// Firebase
import { getDownloadURL, ref, uploadBytesResumable, deleteObject, listAll } from 'firebase/storage';
import { ST } from 'src/firebase';
// Uuid
import { v4 as uuid4 } from 'uuid';

export const removeFile = async (reference) => {
  const storageRef = ref(ST, reference);
  await deleteObject(storageRef);
};

export const removeFolder = async (reference) => {
  const storageRef = ref(ST, reference);
  const { items } = await listAll(storageRef);
  // eslint-disable-next-line
  for(const value of items) {
    // eslint-disable-next-line
    await removeFile(value.fullPath);
  }
};

export const createFile = async (file, reference) => {
  const splitName = file.name.split('.');
  const extension = splitName[splitName.length - 1].toLowerCase();
  const key = `${uuid4()}.${extension}`;
  const firebaseReference = `${reference}/${key}`;
  const storageRef = ref(ST, firebaseReference);
  await uploadBytesResumable(storageRef, file);
  const url = await getDownloadURL(storageRef);
  return { success: true, data: { url, key } };
};

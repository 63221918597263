/* eslint-disable spaced-comment */
/* eslint-disable no-else-return */
import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';

// utils
import axios, { fetcher, endpoints } from 'src/utils/axios';

export function useLandingPagesList(id) {
  const URL = `${endpoints.landing.list}?user_id=${id}`

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      landingdata: data?.data.list || [],
      landingLoading: isLoading,
      landingError: error,
      landingValidating: isValidating,
      landingEmpty: !isLoading && data?.success,
      refreshData: () => mutate(URL)
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.list, error, isLoading, isValidating]
  );

  return memoizedValue;
}


export function useCreateLandingPages() {

  const createData = async (url, newData) => {
    const response = await axios.post(url, newData);

    if (response.status !== 200) {
      console.log(response)
      return {
        status: 'failed',
        message: response?.data?.message || 'failed'
      }
      // throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.data;
  };

  return {
    mutate: async (newData) => {
      const URL = endpoints.landing.create;
      const updatedData = await createData(URL, newData);
      mutate(URL);
      return updatedData;
    },
  };
}

export async function getLandingPage(id, user_id) {


  const URL = `${endpoints.landing.view}?id=${id}&user_id=${user_id}`

  const { data: { data: result }, status } = await axios.get(URL);

  console.log('status = >', status)


  if (status === 200) {


    console.log(result?.result)
    return {
      landingdata: result?.result
    }
  }
  else {
    return {
      landingdata: {}
    }
  }

  // const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);


  // const memoizedValue = useMemo(
  //   () => ({
  //     landingdata: data?.data?.result || null,
  //     landingLoading: isLoading,
  //     landingError: error,
  //     landingValidating: isValidating,
  //     landingEmpty: !isLoading && !Object.keys(data?.data?.result || {}),
  //   }),
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [data?.admin, error, isLoading, isValidating]
  // );

  // return memoizedValue;
}


export function useUpdateLandingPages() {

  const updateData = async (url, newData) => {
    const response = await axios.put(url, newData);

    if (response.status !== 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.data;
  };

  return {
    mutate: async (newData, id) => {
      const URL = `${endpoints.landing.update}?id=${id}`
      const updatedData = await updateData(URL, newData);

      console.log(updateData)
      mutate(URL);
      return updatedData;
    },
  };
}

export function useUpdateSystemLandingPages() {

  const updateData = async (url, newData) => {
    const response = await axios.put(url, newData);

    if (response.status !== 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.data;
  };

  return {
    mutate: async (newData, id, user_id) => {
      const URL = `${endpoints.landing.updateSystem}?id=${id}&user_id=${user_id}`
      const updatedData = await updateData(URL, newData);

      console.log(updateData)
      mutate(URL);
      return updatedData;
    },
  };
}


export function useDeleteLandingPages() {

  const updateData = async (url, newData) => {
    const response = await axios.put(url, newData);

    if (response.status !== 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.data;
  };

  return {
    mutate: async (newData, id) => {
      const URL = `${endpoints.landing.delete}?id=${id}`
      const updatedData = await updateData(URL, newData);

      mutate(URL);
      return updatedData;
    },
  };
}

export function useActivedLandingPages() {

  const updateData = async (url, newData) => {
    const response = await axios.put(url, newData);

    if (response.status !== 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.data;
  };

  return {
    mutate: async (newData, id) => {
      const URL = `${endpoints.landing.activated}?id=${id}`
      const updatedData = await updateData(URL, newData);
      mutate(URL);
      return updatedData;
    },
  };
}

export async function questionsList(id) {
  const URL = endpoints.landing.questions

  const response = await axios.get(`${URL}?user_id=${id}`);

  return response;
}


export async function removeQuestion(body) {
  const URL = endpoints.landing.deleteQuestions

  const response = await axios.delete(URL, {
    data: body
  });

  return response;
}

export async function addOrUpdateQuestion(body) {

  const URL = endpoints.landing.questionAddOrUpdate

  console.log('ADD OR UPDATE => ', URL)

  const response = await axios.post(URL, {
    id: body.landing_page_id,
    question: body,
    type: body.type,
    user_id: body.user_id,
    user_level: body?.user_level,
    organization_id: body?.organization_id
  });

  return response;
}
